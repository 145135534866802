import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Register - The Smile Stylist" 
    description="Register as a patient for Oakville SmileStylist. Call Now! (905) 849-0558" 
    keywords="register with a dentist, porcelain veneers, whiten theeth, teeth canada, dental clinics, oakville, toronto, mississauga, canada" />
    <main className="site-content form__outer-content" role="main">
    <img src={ require("../images/desktop/parallax/blog.jpg")} className="form__outer-banner" />
    <div className="row form__outer-row">
      <div className="col-xs-12">
        <div className="form__outer_outer">
          <form className="form__outery"  method="post" action="/register.php">
            <div className="form__head">
              <h2>Patient Registration Form</h2>
              <p>The following information is required by The Smile Stylist to assist in proper diagnosis and treatment. Please feel free to ask the receptionist for help in completing this form. All information is confidential. </p>
              <sub>* – required field.</sub>
            </div>
            <div className="form__body">
              <div className="form__page _1 _open">
                <div className="row">
                  <label for="how_found" className="label _required">How did you find us</label>
                  <select name="ddl_how" id="how_found" required>
                    <option>Choose</option>
                    <option value="Existing Patient">Existing Patient</option>
                    <option value="Google">Google</option>
                    <option value="Instagram">Instagram</option>
                    <option value="Facebook">Facebook</option>
                    <option value="Twitter">Twitter</option>
                    <option value="Referal">Referal</option>
                    <option value="Real self">Real self</option>
                    <option value="Walk-In">Walk-In</option>
                    <option value="Radio">Radio</option>
                    <option value="TV">TV</option>
                    <option value="Magazine">Magazine</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="row">
                  <label for="full_name" className="label _required">Full Name</label>
                  <input type="text" name="txt_name" id="full_name" required />
                </div>
                <div className="row">
                  <label for="dob" className="label _required">Date of Birth</label>
                  <input type="date" name="dob" id="dob" required />
                </div>
                <div className="row">
                  <label className="label _required">Sex</label>
                  <label for="sex_male">
                    <input name="sex" type="radio" id="sex_male" value="male" required />
                    Male
                  </label>
                  <br />
                  <label for="sex_female">
                    <input name="sex" type="radio" id="sex_female" value="female" required />
                    Female
                  </label>
                </div>
                <div className="row">
                  <label for="marital_status" className="label _required">Marital Status</label>
                  <select name="marital_status" id="marital_status" required>
                    <option>Choose</option>
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Widowed">Widowed</option>
                  </select>
                </div>
                <div className="row">
                  <label for="email" className="label _required">Email</label>
                  <input type="email" name="txt_email" required id="email" />
                </div>
                <div className="row">
                  <label for="address" className="label _required">Address</label>
                  <input type="text" name="address" required id="address" />
                </div>
                <div className="row">
                  <label for="province" className="label _required">Province</label>
                  <select name="txt_state" id="province" required>
                    <option>Choose</option>
                    <option value="ON">ON</option>
                    <option value="AB">AB</option>
                    <option value="BC">BC</option>
                    <option value="NB">NB</option>
                    <option value="NT">NT</option>
                    <option value="NS">NS</option>
                    <option value="NU">NU</option>
                    <option value="PE">PE</option>
                    <option value="QC">QC</option>
                    <option value="SK">SK</option>
                    <option value="YT">YT</option>
                    <option value="From US or Abroad">From US or Abroad</option>
                  </select>
                </div>
                <div className="row">
                  <label for="postal_code" className="label _required">Postal Code</label>
                  <input type="text" name="postal_code" required id="postal_code" />
                </div>
                <div className="row">
                  <label for="cell_number" className="label _required">Cell Number</label>
                  <input type="tel" name="cell_number" required id="cell_number" />
                </div>
                <div className="row">
                  <label for="person_responsible" className="label _required">Person Responsible For Account</label>
                  <select name="person_responsible" id="person_responsible" required>
                    <option>Choose</option>
                    <option value="Myself">Myself</option>
                    <option value="Spouse/Partner">Spouse/Partner</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="row">
                  <label for="occupation" className="label">Occupation</label>
                  <input type="text" name="occupation" id="occupation" /> 
                </div>
                <div className="row">
                  <label for="employer_name" className="label">Employer Name</label>
                  <input type="text" name="employer_name" id="employer_name" />
                </div>
                <div className="row">
                  <label for="employer_phone" className="label">Employer Phone Number</label>
                  <input type="text" name="employer_phone" id="employer_phone" />
                </div>
              </div>
              <div className="form__page _2">
                <h2 className="section-title">Physician Information</h2>
                <div className="row">
                  <label for="current_physician" className="label">Current Family Physician</label>
                  <input type="text" name="current_physician" id="current_physician" />
                </div>
                <div className="row">
                  <label for="physician_number" className="label">Family Physician Number</label>
                  <input type="tel" name="physician_number" id="physician_number" />
                </div>
                <div className="row">
                  <label for="specialist_name" className="label">Specialist Name</label>
                  <input type="text" name="specialist_name" id="specialist_name" />
                </div>
                <div className="row">
                  <label for="specialist_number" className="label">Specialist Phone Number</label>
                  <input type="tel" name="specialist_number" id="specialist_number" />
                </div>
              </div>
              <div className="form__page _3">
                <h2 className="section-title">Insurance</h2>
                <div className="row">
                  <label for="provider" className="label">Provider</label>
                  <input type="text" name="provider" id="provider" />
                </div>
                <div className="row">
                  <label for="policy_number" className="label">Policy Number</label>
                  <input type="text" name="policy_number" id="policy_number" />
                </div>
                <div className="row">
                  <label for="subscriber_id" className="label">Subscriber ID</label>
                  <input type="text" name="subscriber_id" id="subscriber_id" />
                </div>
              </div>
              <div className="form__page _4">
                <h2 className="section-title">Medical History</h2>
                <div className="row">
                  <label className="label _required">Do you have any health problems presently?</label>
                  <label for="problems">
                    <input type="radio" name="problems" required id="problems" value="yes" name="problems" /> Yes
                  </label>
                  <label for="problems_no">
                    <input type="radio" name="problems" required id="problems_no" value="no" name="problems" /> No
                  </label>
                </div>
                <div className="row">
                  <label for="problems_problem" className="label">If you have had health problems please specify</label>
                  <textarea cols="30" rows="10" name="problems_problem" id="problems_problem"></textarea>
                </div>
                <div className="row">
                  <label className="label">Are you under the care of a physician?</label>
                  <label for="care">
                    <input type="radio" name="care" id="care" value="yes" name="care" /> Yes
                  </label>
                  <label for="care_no">
                    <input type="radio" name="care" id="care_no" value="no" name="care" /> No
                  </label>
                </div>
                <div className="row">
                  <label for="xrays" className="label">When were your last dental x-rays taken?</label>
                  <input type="date" name="xrays" id="xrays" />
                </div>
              </div>
              <div className="progress__row _1">
                <span>1</span>
                <span>2</span>
                <span>3</span>
                <span>4</span>
              </div>
              <div className="form__controls">
                <span className="form__back js--back control _back">Back</span>
                <span className="form__next js--next control">Next</span>
                <input type="submit" value="Submit" className="control" className="rsss" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </main>
  </Layout>
)

export default AboutKalotiPage